<template>
  <div></div>
</template>
<script>
export default {
  name: 'tourDashboard',
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped></style>
